// Here you can add other styles
.nav-pills .nav-link {
    color: #000;
}

.nav-pills .nav-link.active {
    background-color: #000;
}

.icon-size svg {
    width: 14px !important;
    margin: 0 3px;
    height: auto !important;
}

.sidebar {
    background-color: #fff !important;
}

.bg-light {
    background-color: #f5f5f5;
}

th {
    padding: 0.5rem 1.5rem !important;
}

td {
    padding: 0.8rem 1.5rem !important;
}

.sidebar-nav .nav-link,
.sidebar-nav .nav-icon,
.sidebar-nav .nav-group.show .nav-group-toggle {
    color: #000;
}

.sidebar-nav .nav-link:hover,
.sidebar-nav .nav-link:focus,
.sidebar-nav .nav-link.active {
    color: #ff0000;
}

.sidebar-nav .nav-link.active svg,
.sidebar-nav .nav-link:hover svg {
    color: #ff0000 !important;
}

.sidebar-nav .nav-group.show {
    background: rgba(255, 191, 191, 0.1);
}

.sidebar-nav .nav-link {
    padding: 0.5rem 0.5rem;
    font-size: 15px;
}

.sidebar-nav .nav-group.show>.nav-group-toggle::after,
.sidebar-nav .nav-group-toggle::after {
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

a {
    color: #000;
}

.btn-primary {
    border-radius: 4px 4px 4px 4px;
    background-color: transparent;
    color: #000;
    border: 3px solid #000;
    padding: 5px 40px 5px 40px !important;
    font-weight: 500;
}

.btn-primary:hover,
.btn-primary:active {
    background-color: #000 !important;
    color: #fff;
    border-color: #000 !important;
}

.btn-primary:hover a {
    color: #fff;
    text-decoration: none;
}

td,
th {
    font-size: 14px
}

.btn-primary a {
    text-decoration: none;
}

.desktop-logo-area {
    border-bottom: 1px solid #e9edf4;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 6px 0px;
}

.sidebar-narrow-unfoldable:not(.sidebar-end)~* {
    --cui-sidebar-occupy-start: 3rem;
}


.sidebar-toggler {
    background-color: rgba($color: #ff0000, $alpha: .5);
}

.sidebar.sidebar-narrow-unfoldable .sidebar-toggler {
    background-color: rgba($color: #ff0000, $alpha: 1);
}

.sidebar .simplebar-mask .simplebar-offset .simplebar-content-wrapper {
    overflow: hidden !important;
}

.sidebar .sidebar-nav .simplebar-track.simplebar-horizontal {
    display: none !important;
}

.breadcrumb li,
.breadcrumb li a {
    font-size: 14px;
}

.cta {
    background: rgba(255, 0, 0, 0.04);
}

.icon-size:hover svg,
.icon-size:hover svg path,
.icon-size:hover svg path rect {
    fill: red;
}

.btn-link {
    color: #000;
}

.btn-link:hover {
    color: red;
}

.nav-underline .nav-link.active,
.nav-underline .show>.nav-link {
    background: transparent;
    color: red;
}

.tabs-nav-uline li .nav-link {
    font-size: 12px;
    padding: 5px 10px;
}

@media (min-width: 768px) {

    .sidebar-fixed.sidebar-narrow-unfoldable,
    .sidebar-fixed.sidebar-narrow {
        z-index: 1031;
        width: 3.5em !important;
    }

    .add-model-popup .modal-dialog {
        --cui-modal-width: 700px;
    }
}

.add-btn-wrapper {
    padding-left: var(--cui-sidebar-nav-icon-width);
}

.color-box {
    display: inline-block;
    width: 50px;
    overflow: hidden;
    // background-color: #000;
    height: 50px;
    border-radius: 2px;
}

.color-box img {
    aspect-ratio: 1;
    border-radius: 10px;
}

.category-title button {
    opacity: 0;
    transition: all 200ms ease-in;
}

.category-title:hover button {
    opacity: 1;
}

.category-input-wrapper,
.category-input-point-wrapper,
.list-input-wrapper {
    position: relative;
}

.category-input-wrapper>button,
.category-input-point-wrapper>button,
.list-input-wrapper button {
    position: absolute;
    width: 15px;
    height: 15px;
    font-size: 12px;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    right: 10px;
    border: none;
    background-color: #f85e5e;
    border-radius: 50%;
}

.category-input-wrapper>button {
    top: 30px;
}

.category-input-point-wrapper>button {
    top: -5px;
    right: 0;
}

.category-input-header {
    min-height: unset;
    padding: 1rem;
}

.category-input-remove-btn {
    width: 20px;
    font-size: 8px;
    height: 20px;
    color: #fff;
    background-color: #f85e5e !important;
    border-radius: 50%;
}

.category-point-input input::-webkit-inner-spin-button,
.category-point-input input::-webkit-outer-spin-button {
    display: none;
}

.category-point-input.input-group input.form-control {
    flex: unset;
}

.category-point-add-btn:disabled {
    background-color: rgba(0, 0, 0, 0.5);
    border-color: transparent;
}

.form-features-wrapper p {
    margin: 0 !important;
}

.form-features-wrapper ul {
    padding-left: 1rem;
}


/* chat css */
.chat-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chat-content {
    overflow-y: scroll;
    flex: 1 1 0;
    padding: 0.5rem 1rem;
    margin-right: 8px;
    height: 100%;
    scroll-behavior: smooth;
}


.chat-content::-webkit-scrollbar {
    cursor: grab;
    width: 8px;
}
.chat-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    max-height: 60px !important;
    cursor: grab;
    background-color: #b0b0b0;
}


.chat-input-wrapper {
    position: relative;
    min-height: 40px;
}


.chat-input-wrapper.disabled::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #f5f5f5, $alpha: 0.1);
    filter: blur(1px);
}

.msg-wrapper {
    margin-bottom: 0.5rem;
}

.msg-wrapper p {
    margin: 0;
}

.msg-wrapper .sender {
    text-align: right;
}

.sender,
.recipient {
    position: relative;
}

.msg {
    display: inline-block;
    padding: 0.5rem;
    max-width: 85%;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
}

.msg-head {
    display: block;
    font-size: 0.625rem;
    color: #ccc;
}

.chat-input-wrapper {
    display: flex;
    padding: 0.5rem;
    column-gap: 0.5rem;
    background-color: #e9edf4;
}

.input-box-wrapper {
    flex-grow: 1;
}

.input-box-wrapper input {
    width: 100%;
    padding: 0 5px;
    height: 100%;
    border-radius: 5px;
    border: none;
    outline: none;
}

.input-box-wrapper input:disabled {
    background-color: #fff;
}

.button-wrapper button {
    padding: 0.5rem;
    background-color: #ff0000;
    border-radius: 5px;
    color: #fff;
    border: none;
    outline: none;
}

.button-wrapper button:disabled {
    background-color: #f25858;
}

.m-cta-btn:hover {
    color: #fff;
}


/* overriding font size for place orders table */
.place-orders-table * {
    font-size: 12px;
}

/* toast css override */

.Toastify__toast--error {
    border: 1px solid #EB5757;
    border-radius: 50px !important;
    background: #FAE1E2 !important;
}

.Toastify__toast--error::after {
    //   content : url('../assets/images/svg/closeToast.svg'); // Your svg Path
    position: absolute;
    color: #333333;
    font-size: 15px;
    font-weight: 700;
    left: 265px;
    padding-top: 14px !important;
}

.Toastify__toast--error::before {
    //   content: url("../assets/images/svg/errorToast.svg");// Your svg Path
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
}

.Toastify__toast--success {
    border: 1px solid #3A9EA5 !important;
    border-radius: 50px !important;
    background: #F0F9FA !important;
}

.Toastify__toast--success::before {
    //   content: url("../assets/images/svg/successToast.svg");// Your svg Path
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
}

.Toastify__toast--success::after {
    //   content : url('../assets/images/svg/closeToast.svg');// Your svg Path
    position: absolute;
    color: #333333;
    font-size: 15px;
    font-weight: 700;
    left: 265px;
    padding-top: 14px !important;
}

.Toastify__toast--warning {
    border: 1px solid #E78326 !important;
    border-radius: 50px !important;
    background: #FADFC5 !important;
}

.Toastify__toast--warning::before {
    //   content: url("../assets/images/svg/warnToast.svg");// Your svg Path
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
}

.Toastify__toast--warning::after {
    //   content : url('../assets/images/svg/closeToast.svg'); // Your svg Path
    position: absolute;
    color: #E78326;
    font-size: 15px;
    font-weight: 700;
    left: 265px;
    padding-top: 14px !important;
}

.Toastify__toast-body {
    color: #444C63;
    font-size: 16px;
    padding-left: 20px;
    line-height: 20px;
    padding: 0px;
    padding-top: 25px;
    width: 100%;
    font-weight: 400;
    margin-left: 25px !important;
    margin-right: 25px !important;
}

.Toastify__toast>button {
    position: absolute;
    top: 20px;
    right: 20px;
}

// .Toastify__toast > button>  svg {
// display: none;
// }

// calendar styles
.calendar-table {
    position: relative;
}

.calendar-table.loading::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.month {
    display: flex;
    column-gap: 10px;
}

.week-days {
    display: flex;
}

.month-row,
.calendar-table thead {
    border-bottom: 1px solid #DBDADE;
}

.calendar-table thead {
    border-top: 1px solid #DBDADE;
}

.week-cell {
    border-left: 1px solid #DBDADE;
}

.calendar-table th,
.calendar-table td {
    padding: 0 !important;
}

.calendar-table th.month-cell,
.calendar-table th.week-cell,
.calendar-table td.month-cell {
    font-weight: 500;
}

.month-cell {
    min-width: 150px !important;
}

.week-cell {
    min-width: 350px !important;
}

.calendar-cell {
    display: flex;
    width: 100%;
    align-items: center;
}

th .calendar-cell {
    padding: 10px;
}

.calendar-nav-btn {
    outline: none !important;
    border: none !important;
}

.calendar-nav-btn:disabled {
    cursor: auto;
    opacity: 0.5;
}

.calendar-wrapper {
    overflow-x: scroll;
}

.calendar-cell .week {
    width: 100%;
}

.calendar-wrapper .orders-container {
    display: flex;
    // column-gap: 8px;
    row-gap: 8px;
    flex-direction: column;
    min-height: 114px;
    padding: 10px;
    width: 100%;
}

.orders-container .order ,
.draglayer .order{
    position: relative;
    display: block; //inline-block
    min-width: 200px;
    width: 100%;
    padding: 5px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 5px;
    background: linear-gradient(0deg, #FFF1E3, #FFF1E3);
    border-left: 3px solid transparent;
    // background-repeat: repeat-x;
    // background-size: 100%;
    // color: #333333;
    color: #fff;
}
.orders-container .order .details{
    position: relative;
    z-index: 1;
}

.orders-container .order .hatch-block{
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../../public/hatch.png');
    background-position: center;
    background-size: 150%;
    pointer-events: none;
    top: 0;
    left: 0;
}

.orders-container .order p ,
.draglayer .order p {
    white-space: nowrap;
}

.orders-container .order span ,
.draglayer .order span {
    font-weight: 600;
}

.calendar-table .order * ,
.draglayer .order * {
    pointer-events: none;
}

.orders-container .order .order-action-wrapper {
    cursor: default;
    pointer-events: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    z-index: 1;
    // background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    // box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    // box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    transition: opacity 200ms ease;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.orders-container .order .order-action-wrapper button {
    pointer-events: all;
    cursor: pointer;
    font-size: 11px;
    padding: 1px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #DBDADE;
    width: 100%;
    transition: background-color 100ms ease;
}

.orders-container .order .order-action-wrapper button:hover {
    background-color: #F0F9FA;
}

.orders-container .order .order-action-wrapper button:disabled {
    cursor: default;
}

.orders-container .order:hover .order-action-wrapper {
    pointer-events: all;
    opacity: 1;
}


// .orders-container .order.dragging{
//     color: red !important;
//     // position: fixed;
//     z-index: 100;
//     top: 0;
// }

.loader {
    position: relative;
}

.loader::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.loader::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 5px solid #ff0000;
    border-top-color: transparent;
    z-index: 2;
    animation: rotate 800ms ease-in infinite;
    transition: all 800ms ease;
}

@keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.build-navs{
    flex-wrap: nowrap;
    border: none;
    // transition: all 100ms ease-in;
    overflow: hidden;
    overflow-x: scroll;
    scroll-behavior: smooth;
}

.pills-container{
    justify-content: flex-start !important;
}

.build-navs::-webkit-scrollbar{
    display: none;
}

.build-navs .nav-link ,
.pills-container .pill-btn{
    white-space: nowrap;
    color: #000;
    font-size: 0.875rem;
    border: none;
    // min-width: 100px;
    // width: 100%;
}
.pills-container .pill-btn.active,
.build-navs .nav-link.active {
    background-color: #333333;
    color: #fff;
    border-radius: 5px;
}

.build-page-body.body {
    --h-h: calc(4rem + 1rem + 1.5rem + 1.5rem);
    --f-h: calc(3rem + 1rem + 1px);
    --o-height: calc(var(--f-h) + var(--h-h));
    height: calc(100vh - var(--o-height));
}

.build-page-body .card {
    height: calc(100% - 1.2rem);
}

.tabs-menu-container {
    height: 42px;
    position: relative;
    overflow: hidden;
}
.pills-wrapper{
    // flex-grow: 1;
    position: relative;
    // overflow: hidden;
    padding: 1.2rem 0;
}

.menu-tabs-content-container {
    height: calc(100% - calc(36px + 1rem));
}

.menu-tabs-content-container .tab-content {
    height: 100%;
    overflow: hidden;
    padding-left: 0.5rem;
    overflow-y: scroll;
}

.menu-tabs-content-container .tab-content::-webkit-scrollbar ,
.custom-scroll::-webkit-scrollbar {
    cursor: grab;
    width: 8px;
}
.custom-scroll::-webkit-scrollbar {
    height: 8px;
}

.menu-tabs-content-container .tab-content::-webkit-scrollbar-thumb,
.custom-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    max-height: 60px !important;
    cursor: grab;
    background-color: #b0b0b0;
}
.custom-scroll::-webkit-scrollbar-thumb{
    height: 3px;
}

.tabs-menu-container .nav-btn,
.pills-wrapper .nav-btn{
    display: none;
    opacity: 0;
    position: absolute;
    height: 40px;
    padding: 0;
    color: #c7c7c8;
    z-index: 1;
    width: 40px;
    top: 50%;
    border-radius: 50%;
    box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.1);
    border: none;
    background-color: #F0F9FA;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
    transform: translateY(-50%);
    transition: all 300ms ease-in;
}
.tabs-menu-container .nav-btn.active,
.pills-wrapper .nav-btn.active{
    display: flex;
    animation: fadeIn 100ms ease-in forwards;
}
@keyframes  fadeIn{
    0%{
        opacity: 0.1;
    }
    50%{
        opacity: 0.5;
    }
    100%{
        opacity: 1;
    }
}
.tabs-menu-container .nav-btn.left,
.pills-wrapper .nav-btn.left{
    left: 0;
}
.tabs-menu-container .nav-btn.right,
.pills-wrapper .nav-btn.right{
    right: 0;
}
.tabs-menu-container .nav-btn.left::after,
.pills-wrapper .nav-btn.left::after{
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    z-index: -1;
    left: 0;
    width: 100%;
    filter: blur(0.5px);
}
.tabs-menu-container .nav-btn.right::before,
.pills-wrapper .nav-btn.right::before{
    content: '';
    position: absolute;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    right: 0;
    filter: blur(0.5px);
}

@media screen and (min-width : 1200px) {
    .build-navs {
        justify-content: center;
    }
}

@media(max-width:1100px) {
    // .build-navs {
    //     overflow: hidden;
    //     overflow-x: scroll;
    //     scroll-behavior: smooth;
    //     // margin-bottom: 2rem;
    // }

    .build-navs .nav-link {
        white-space: nowrap;
    }
}

.thumbnail-wrapper{
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px;
}

.thumbnail-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.thumbnail-wrapper .rm-btn{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 2px;
    right: 2px;
    width: 15px;
    padding: 2px;
    height: 15px;
    border-radius: 50%;
    border: none;
    font-size: 1.2rem;
    outline: none;
    background-color: #EB5757;
    color: #fff;
}
.thumbnail-wrapper .rm-btn:hover,
.thumbnail-wrapper .rm-btn:focus,
.thumbnail-wrapper .rm-btn:focus-visible,
.thumbnail-wrapper .rm-btn:active{
    background-color: #d54e4e;
}

.multi-select .multi__value-container::-webkit-scrollbar{
    display: none;
}

.form-check{
    font-size: 1.2rem;
}

// vin number plate
.vin-number-plate{
    border: 1px solid #000;
    box-shadow: 1px 1px 0px 1px #000;
    padding: 0.5rem;
    position: relative;
    transform: scale(0.8);
    color: #fff;
}


.vin-number-plate input{
    color: #000;
    min-width: 100px !important;
    background-color: #fff !important;
    border: none !important;
    border-radius: 0px !important;
    padding: 5px 10px !important;
}

.vin-number-plate input.fix-width{
    width : 113px !important;
}

.vin-number-plate label{
    font-size: 1.05rem;
    text-transform: uppercase;
    white-space : nowrap;
    font-family: serif !important;
}

.vin-number-plate .number-plate-details{
    background-color: #000;
    padding: 20px;
    position: relative;
}

.vin-number-plate::after,
.vin-number-plate::before,
.number-plate-details::after,
.number-plate-details::before{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border: 3px solid #fff;
    border-radius: 50%;
    z-index: 1;
}
.vin-number-plate::after{
    top: 20px;
    right: 20px;
}
.vin-number-plate::before{
    top: 20px;
    left: 20px;
}
.number-plate-details::after{
    bottom: 10px;
    right: 10px;
}
.number-plate-details::before{
    bottom: 10px;
    left: 10px;
}

// @media print{
//     // .wrapper > div.body .card{
//     //     background: rgb(144, 238, 144) !important;
//     // }
//     .sidebar.sidebar-fixed{
//         display: none;
//     }
//     .sidebar:not(.sidebar-end) ~ * {
//         --cui-sidebar-occupy-start: 0rem;
//     }
// }

.bottom-contact{
    background-color: #e0e0e0;
    color: #000;
    padding: 10px 15px;
    text-align: center;
}
.bottom-contact a{
    color: #ff0000 !important;
    font-size: 12px;
}